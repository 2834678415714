import React from "react";
import PropTypes from "prop-types";
import * as style from "../styles/page-header.module.scss";

const Background = ({ children, post, image }) => {
    const backgroundStyles = {
        backgroundImage: `url(${image})`,
    };

    return (
        <>
            <div
                className={`${style.clarsyntHeader} ${
                    post && `p-0 text-start`
                }`}
                style={backgroundStyles}
            >
                {children}
            </div>
        </>
    );
};

Background.propTypes = {
    image: PropTypes.string,
    post: PropTypes.bool,
    children: PropTypes.node,
};

const PageHeader = ({ post, image, children, headerText }) => (
    <>
        <Background post={post} image={image}>
            <div
                className={`${style.clarsyntHeaderContent}`}
                style={post && { minHeight: `360px` }}
            >
                {children && (
                    <div
                        className={`clarsynt-container ${style.clarsyntHeaderContentChildren}`}
                    >
                        {children}
                        <h1 className={`text-start`}>{headerText}</h1>
                    </div>
                )}
                {!children && (
                    <h1 className={`clarsynt-container`}>{headerText}</h1>
                )}
            </div>
        </Background>
    </>
);

PageHeader.propTypes = {
    image: PropTypes.string,
    post: PropTypes.bool,
    headerText: PropTypes.string,
};

export default PageHeader;
