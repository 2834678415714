import React from "react";
import PropTypes from "prop-types";
// import { Link } from 'gatsby'
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Link } from "gatsby";
import clarsyntLogoFullImg from "../images/Clarsynt-logo.png";
import * as style from "../styles/clarsynt-navigation.module.scss";

const Linker = (props) => (
    <>
        <Nav.Item>
            <Link
                activeClassName="active"
                className="link-no-style"
                to={props.target}
            >
                <Nav.Link
                    className={props.className}
                    as="div"
                    href={props.target}
                >
                    {props.children}
                </Nav.Link>
            </Link>
        </Nav.Item>
    </>
);
Linker.propTypes = {
    target: PropTypes.string,
    className: PropTypes.string,
};

const Submenu = (props) => (
    <div className={`${style.submenu}`}>{props.children}</div>
);

// eslint-disable-next-line no-unused-vars
const ClarsyntNavigation = ({ variant }) => (
    <>
        <div className={`${style.clarsyntNavBox}`}>
            <div className={`${style.navLogo}`}>
                <Linker target="/">
                    <img
                        alt="Clarsynt, see further"
                        src={clarsyntLogoFullImg}
                    />
                </Linker>
            </div>
            <Navbar
                variant={variant ? variant : `light`}
                expand="lg"
                collapseOnSelect
            >
                <Navbar.Toggle aria-controls="clarsynt-navbar-nav" />
                <Navbar.Collapse id="clarsynt-navbar-nav">
                    <Nav>
                        <div className={`${style.submenuWrapper}`}>
                            <Linker target="/offers">What We Offer</Linker>
                            <Submenu>
                                <Linker
                                    className={`${style.submenuItem}`}
                                    target="/operational-efficiency"
                                >
                                    Operational Efficiency
                                </Linker>
                                <Linker
                                    className={`${style.submenuItem}`}
                                    target="/managerial-effectiveness"
                                >
                                    Managerial Effectiveness
                                </Linker>
                                <Linker
                                    className={`${style.submenuItem}`}
                                    target="/transformation-with-ai"
                                >
                                    Transformation With AI
                                </Linker>
                            </Submenu>
                        </div>
                        <div className={`${style.submenuWrapper}`}>
                            <Linker target="/about">About</Linker>
                            <Submenu>
                                <Linker
                                    className={`${style.submenuItem}`}
                                    target="/our-approach"
                                >
                                    Your Journey With Us
                                </Linker>
                                <Linker
                                    className={`${style.submenuItem}`}
                                    target="/about#our-leadership"
                                >
                                    Meet the Team
                                </Linker>
                            </Submenu>
                        </div>
                        <div className={`${style.submenuWrapper}`}>
                            <Linker target="/insights">Insights</Linker>
                            <Submenu>
                                <Linker
                                    className={`${style.submenuItem}`}
                                    target="/insights"
                                >
                                    Articles
                                </Linker>
                                <Linker
                                    className={`${style.submenuItem}`}
                                    target="/insights/case-studies"
                                >
                                    Case Studies
                                </Linker>
                            </Submenu>
                        </div>
                        <div className={`${style.insightNavBox}`}>
                            <Linker target="/contact" className={`text-light`}>
                                Contact
                            </Linker>
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    </>
);

ClarsyntNavigation.defaultProps = {
    variant: `light`,
};

ClarsyntNavigation.propTypes = {
    variant: PropTypes.string,
};

export default ClarsyntNavigation;
