import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

import favicon from "../../images/favicon.png";

// Styles
import "../../styles/app.scss";
import ClarsyntNavigation from "../ClarsyntNavigation";
import PageHeader from "../PageHeader";
import ClarsyntFooter from "../ClarsyntFooter";

/**
 * Main layout component
 *
 * The Layout component wraps around each page and template.
 * It also provides the header, footer as well as the main
 * styles, and meta data for each page.
 *
 */
const DefaultLayout = ({
    data,
    children,
    bodyClass,
    headerImage,
    headerText,
    headerCategory,
    post,
    tag,
}) => {
    const site = data.allGhostSettings.edges[0].node;
    // const twitterUrl = site.twitter ? `https://twitter.com/${site.twitter.replace(/^@/, ``)}` : null
    // const facebookUrl = site.facebook ? `https://www.facebook.com/${site.facebook.replace(/^\//, ``)}` : null
    return (
        <>
            <Helmet>
                <html lang={site.lang} />
                <style type="text/css">{`${site.codeinjection_styles}`}</style>
                <link rel="icon" href={favicon} />
                <body className={bodyClass} />
            </Helmet>
            <ClarsyntNavigation />
            {headerImage && (
                <PageHeader
                    post={post}
                    tag={tag}
                    headerText={headerText}
                    image={headerImage}
                >
                    {headerCategory}
                </PageHeader>
            )}
            <main className="p-0">
                {/* All the main content gets inserted here, index.js, post.js */}
                {children}
            </main>
            {/* footer goes here */}
            <ClarsyntFooter />
        </>
    );
};

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    bodyClass: PropTypes.string,
    isHome: PropTypes.bool,
    data: PropTypes.shape({
        file: PropTypes.object,
        allGhostSettings: PropTypes.object.isRequired,
    }).isRequired,
    headerText: PropTypes.any,
    headerCategoryText: PropTypes.string,
    headerCategory: PropTypes.string,
    headerImage: PropTypes.string,
    homepage: PropTypes.bool,
    post: PropTypes.bool,
    tag: PropTypes.bool,
};

const DefaultLayoutSettingsQuery = (props) => (
    <StaticQuery
        query={graphql`
            query GhostSettings {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
                file(relativePath: { eq: "ghost-icon.png" }) {
                    childImageSharp {
                        gatsbyImageData(layout: FIXED)
                    }
                }
            }
        `}
        render={(data) => <DefaultLayout data={data} {...props} />}
    />
);

export default DefaultLayoutSettingsQuery;
