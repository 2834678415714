import React from "react";
import { Nav } from "react-bootstrap";
import * as style from "../styles/clarsynt-footer.module.scss";

import clarsyntLogoIconImg from "../images/Clarsynt-logo_full_icon.png";
import linkedinIcon from "../images/icons/linkedin.svg";

const ClarsyntFooter = () => (
    <footer className={`${style.clarsyntFooter}`}>
        <div className={`${style.clarsyntFooterNav} py-4`}>
            <Nav
                className={`ghost-container container justify-content-center justify-content-lg-between`}
            >
                <Nav.Item>
                    <Nav.Link href="/">Home</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="/offers">What We Offer</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="/about">About</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="/our-approach">
                        Your Journey With Us
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="/insights">Insights</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="/contact">Contact</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link
                        href="https://www.linkedin.com/company/clarsynt/"
                        target="_blank"
                    >
                        <img src={linkedinIcon} alt="Linkedin icon" />
                    </Nav.Link>
                </Nav.Item>
            </Nav>
        </div>
        <div
            className={`clarsynt-container pt-5 pb-4 ${style.clarsyntFooterInfo}`}
        >
            <div className={`d-flex flex-row justify-content-center pt-4`}>
                <img
                    alt="Clarsynt, see further"
                    src={clarsyntLogoIconImg}
                    className={`${style.clarsyntLogoIcon}`}
                />
            </div>
            <div className={`d-flex flex-row justify-content-center pt-4`}>
                <p
                    className={`${style.clarsyntFooterInfoCopyright} text-center text-secondary`}
                >
                    &copy;{new Date().getFullYear()} Clarsynt | Minneapolis, MN
                    | <a href="/">Privacy Policy</a> |{` `}
                    <a href="/contact/">Join the Team</a>
                </p>
            </div>
        </div>
    </footer>
);

export default ClarsyntFooter;
